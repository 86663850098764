import React from "react";
import {Trans} from "react-i18next";

import "./SimpleInfoRow.scss";

const SimpleInfoRow = (props) => {
  return (
    <div className={'simple-row'}>
      <label><Trans>{props.label}</Trans>:</label><span>{props.value}</span>
    </div>
  );
};

export default SimpleInfoRow;