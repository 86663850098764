import React from "react";
import AppBar from "../AppBar/AppBar";

export default (props) => {
  return (
    <div className="layout">
      <AppBar />
      <main>
        {props.children}
      </main>
    </div>
  );
};