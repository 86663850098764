import {withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import MaterialButton from "@material-ui/core/Button/Button";
import React from "react";


const styles = {
  root: {
    marginLeft: 5
  }
};

const ButtonSpinner = withStyles(styles)(() => (
  <CircularProgress
    className="button__spinner"
    size={24}
    color="inherit"
  />
));

const Button = (props) => {
  const {
    children,
    working,
    disabled,
    ...rest
  } = props;

  return (
    <MaterialButton {...rest} disabled={disabled || working}>
      {working ? <ButtonSpinner {...rest} /> : children}
    </MaterialButton>
  )
};

export default Button;