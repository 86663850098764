import React from "react";
import {Trans} from "react-i18next";
import Button from "../../components/Button/Button";
import * as utils from "../../utils";


class UpgradeSiteButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    utils.openLegacyUpgrade(this.props.siteId);
  }

  render() {
    const {
      siteId,
      label,
      ...rest
    } = this.props;

    return (
      <Button className="button" onClick={this.handleClick} {...rest}><Trans>{label ? label : 'Common.UpgradeSiteButton'}</Trans></Button>
    );
  }
}
export default UpgradeSiteButton;