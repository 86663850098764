import React from "react";

import SiteSeo from "./SiteSeo";
import SiteInfo from "./SiteInfo";
import SiteUpgrade from "./SiteUpgrade";

import * as utils from "../../utils";


class SiteDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      site: {
        creationDate: 0,
        modificationDate: 0,
        displayName: '',
        subscriptionPackage: '',
        subscriptionPeriodEnd: 0,
        domains: []
      }
    };
  }

  componentDidMount() {
    utils.callAPI("sites/" + this.props.match.params.siteId).then(
      data => {
        this.setState({ready: true, site: data.site});
      }
    ).catch(utils.errorHandler);
  }

  render() {
    const siteId = this.props.match.params.siteId;
    return (
      <div>
        <div style={{width: 1000, display: "inline-block", verticalAlign: 'top'}}>
          <SiteInfo id={siteId}/>
          {this.state.site.free
            ? <SiteUpgrade site={this.state.site}/>
            : ''
          }
        </div>
        <div style={{width: 1000, display: "inline-block", verticalAlign: 'top'}}>
          <SiteSeo id={siteId}/>
        </div>
      </div>
    );
  }
}

export default SiteDashboard;