import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

import logo from "../../media/logo.svg";
import "./PublicBase.scss";


const PublicBase = (props) => {
    return (
        <div className="public__body">
            <div className="public__container">
                <div className="public__info">
                    <h2 className="public__info__headline">
                        {props.infoHeadline}
                    </h2>
                  {props.infoText.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </div>
                <div className="public__content">
                  <img className="public__logo" src={logo} alt="Logo" />
                  <div className="public__content__headline">
                    {props.contentHeadline}
                  </div>
                  {props.inProgress
                    ? <CircularProgress size={48} color="inherit" style={{marginTop:110,marginLeft:250}}/>
                    : props.children
                  }
                </div>
            </div>
        </div>
    );
};

export default PublicBase;