import React from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

import Button from "../../components/Button/Button";
import UpgradeSiteButton from "./UpgradeSiteButton";
import ViewSiteButton from "./ViewSiteButton";
import EditSiteButton from "./EditSiteButton";

import * as utils from "../../utils";

class SiteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: true,
      sites: [],
    };
  }

  componentDidMount() {
    utils.callAPI("sites").then(
      data => {
        this.setState({
          sites: data.sites
        })
      }
    ).catch(error => {
      utils.log("ERROR: ", error);
    });
  }

  render() {
    let listItems = this.state.sites.map((site) => {
      return (
        <SiteListItem key={site.id} {...site} />
      );
    });
    return (
      <div className="sites__list">
        {listItems}
      </div>
    );
  }
}

class SiteCallToAction extends React.Component {
  constructor(props) {
    super(props);
    this.handleCallToAction = this.handleCallToAction.bind(this);
  }

  handleCallToAction(e) {
    window.c4p_upgrade.show(this.props.legacyId);
  }

  render() {
    return (
      this.props.variant !== 'empty'
        ? <div className="sites__call-to-action">
          <div className="sites__call-to-action__headline"><Trans>SitesOverview.CallToActionHeadline-{this.props.variant}</Trans></div>
          <div className="sites__call-to-action__text"><Trans>SitesOverview.CallToActionText-{this.props.variant}</Trans></div>
          <UpgradeSiteButton siteId={this.props.id} label={'SitesOverview.CallToActionButton-' + this.props.variant} className="sites__call-to-action__button button" size="large" variant="contained" color="primary"/>
        </div>
        : <div className="sites__call-to-action">
          <div className="sites__call-to-action__headline">Enjoy!</div>
          <div className="sites__call-to-action__text">Thank you for subscribing to a premium package.</div>

        </div>
    );
  }
}

const SiteScreenshot = (props) => {
  return (
    <div className="sites__base-info">
      <div className="sites__screenshot">
        <div className="corner-ribbon red">{props.subscriptionPackage}</div>
        <Link to={'/sites/' + props.id + '/dashboard/'}><Button className="sites__dashboard-button"><Trans>SitesOverview.ShowDashboard</Trans></Button></Link>
      </div>
    </div>
  )
};

class SiteListItem extends React.Component {
  render() {
    return (
      <section className="sites__listitem">
        <h2>{this.props.displayName}</h2>
        <div style={{display:'flex',padding:10}}>
          <SiteScreenshot {...this.props}/>
          <SiteCallToAction {...this.props} variant={this.props.free ? 'upgrade' : 'empty'}/>
        </div>
        <footer>
          <ViewSiteButton style={{flexGrow:1,margin:5}} siteId={this.props.id}/>
          <EditSiteButton style={{flexGrow:1,margin:5}} siteId={this.props.id}/>
        </footer>
      </section>
    );
  }
}

export default SiteList;