import i18n from 'i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  //.use(LanguageDetector)
  .init({
    resources: {
      en: {
        translations: {
          "Common.Save": "Save",
          "Common.SaveSuccess": "Your settings have been updated",
          "Common.SaveError": "Your settings have NOT been saved.",
          "Common.ViewSiteButton": "View site",
          "Common.EditSiteButton": "Edit site",
          "Common.UpgradeSiteButton": "Upgrade Now!",

          "AppBar.LinkAccount": "My account",
          "AppBar.LinkSites": "My websites",
          "AppBar.LinkDomains": "My domains",
          "AppBar.LinkSupport": "Support",
          "AppBar.LinkLogout": "Logout",

          "Login.InfoHeadline": "Go your own way",
          "Login.InfoText": "Please use your credentials to login.",
          'Login.Email': "Email Address",
          "Login.Password": "Password",
          "Login.Button": "Login",
          "Login.ForgotPassword": "Forgot password ?",
          "Login.FormHeadline": "Login",
          "Login.EmailInvalid": "Please enter a valid email address",
          "Login.PasswordInvalid": "Email and/or password is incorrect",

          "ForgotPwd.InfoHeadline": "Go your own way",
          "ForgotPwd.InfoText": "Please enter your email address associated with your account",
          "ForgotPwd.FormHeadline": "Request an password reset",
          "ForgotPwd.Email": "Email address",
          "ForgotPwd.BackToLogin": "Back to login",
          "ForgotPwd.Button": "Send request",

          "ForgotPwd.EmailInvalid": "Please enter a valid email address",
          "ForgotPwd.MessageSent": "Please check your mailbox for a message with instructions on how to reset your password",

          "ResetPassword.InfoHeadline": "Go your own way",
          "ResetPassword.InfoText": "Your new password must be at least 8 characters long and must contain the following elements:\n\nAn uppercase character, a lowercase character and a number.",
          "ResetPassword.FormHeadline": "Reset your password",
          "ResetPassword.NewPassword": "New password",
          "ResetPassword.NewPasswordRepeat": "Repeat the new password",
          "ResetPassword.Button": "Update password",
          "ResetPassword.PasswordError": "Your password doesn't meet our security requirements",
          "ResetPassword.RepeatError": "Please repeat your new password",
          "ResetPassword.Success": "Your password has been successfully updated",
          "ResetPassword.Error": "The provided recovery key was either invalid, already used or expired",

          "PersonalData.Headline": "Your personal data",
          "PersonalData.InfoText": "To change your email address please contact our support",
          "PersonalData.Email": "Email",
          "PersonalData.Organisation": "Organisation",
          "PersonalData.FirstName": "First name",
          "PersonalData.LastName": "Last name",
          "PersonalData.Street": "Street",
          "PersonalData.StreetNumber": "Number",
          "PersonalData.PostCode": "Postcode",
          "PersonalData.City": "City",
          "PersonalData.Country": "Country",
          "PersonalData.Phone": "Phone",
          "PersonalData.Fax": "Fax",
          "PersonalData.Submit": "Save",
          "PersonalData.SaveSuccess": "Your personal data has been updated",
          "PersonalData.SaveError": "Your personal data could not been updated",

          "Password.Headline": "Change password",
          "Password.OldPassword": "Your current password",
          "Password.NewPassword": "The new password",
          "Password.NewPasswordRepeat": "Repeat the new password",
          "Password.Submit": "Change password",
          "Password.SaveSuccess": "Your password has been successfully updated",
          "Password.SaveError": "Your password was NOT updated. Please check and try again.",
          "Password.IncorrectError": "Your password is not correct",
          "Password.RepeatError": "Please repeat your new password",
          "Password.InfoText": "",

          "PaymentInfo.Headline": "Your payment information",
          "PaymentInfo.InfoText": "Your payment method is stored by our payment provider Stripe and used to process future payments",
          "PaymentInfo.NoPaymentMethod": "No payment method stored",
          "PaymentInfo.Update": "Update Payment Method",

          "SitesOverview.Headline": "My websites",
          "SitesOverview.Info": "Overview of your page4 websites and projects",
          "SitesOverview.ViewButton": "View site",
          "SitesOverview.EditButton": "Edit site",

          "SitesOverview.ShowDashboard": "Show dashboard",

          "SitesOverview.CallToActionHeadline-upgrade": "Upgrade today!",
          "SitesOverview.CallToActionText-upgrade": "You are missing out on important features",
          "SitesOverview.CallToActionButton-upgrade": "Upgrade Now!",

          "SiteInfo.Headline": "Overview",
          "SiteInfo.Created": "Created",
          "SiteInfo.LastLogin": "Last login",
          "SiteInfo.DisplayName": "Name",
          "SiteInfo.SubscriptionPackage": "Subscription package",
          "SiteInfo.SubscriptionUntil": "Subscription until",
          "SiteInfo.Domains": "Domains",
          "SiteInfo.Unlimited": "Unlimited",

          "SiteSeo.Headline": "Website SEO Settings",
          "SiteSeo.Title": "Title",
          "SiteSeo.Author": "Author",
          "SiteSeo.Keywords": "Keywords",
          "SiteSeo.Description": "Description",

          "SiteUpgrade.Headline": "Upgrade",
          "SiteUpgrade.Info": "You are missing out on important features, like your own domain or advanced SEO features.",

          "SupportForm.Headline": "Support form",
          "SupportForm.InfoText": "If you have any questions or need support with your homepage please don't hesitate to contact our Support using our online form. " +
            "If your message is related to an specific account/website please select the account to accelerate the support process. Thank you.",
          "SupportForm.Email": "Your email",
          "SupportForm.Reason": "Reason for your request",
          "SupportForm.Site": "The request is regarding which website?",
          "SupportForm.Name": "Your name",
          "SupportForm.Message": "Your message",
          "SupportForm.Submit": "Send support message",
          "SupportForm.Reason0": "General",
          "SupportForm.Reason1": "Question",
          "SupportForm.Reason2": "Account",
          "SupportForm.Reason3": "Domain",
          "SupportForm.NoSiteSelected": "General question/no specific account",
          "SupportForm.SendSuccess": "Your message has been successfully sent",
          "SupportForm.SendError": "Your message could not been sent"
        }
      },
      de: {
        translations: {
          "AppBar.LinkAccount": "Mein Benutzerkonto",
          "AppBar.LinkSites": "Meine Webseiten",
          "AppBar.LinkDomains": "Meine Domains",
          "AppBar.LinkSupport": "Support",
          "AppBar.LinkLogout": "Ausloggen ",

          "Login.InfoHeadline": "Geh deinen eigenen Weg",
          "Login.InfoText": "Bitte benutze deine Zugangsdaten um dich einzuloggen.",
          "Login.Email": "Emailadresse",
          "Login.Password": "Passwort",
          "Login.Button": "Einloggen",
          "Login.ForgotPassword": "Passwort vergessen ?",
          "Login.FormHeadline": "Einloggen",

          "Password.Submit": "Passwort ändern",
        }
      },
      fr: {
        translations: {
          "Login.InfoHeadline": "Suis ton propre chemin",
          "Login.InfoText": "Veuillez utiliser vos données d'accès pour vous connecter.",
          "Login.Email": "Adresse Email",
          "Login.Password": "Mort de passe",
          "Login.Button": "Se connecter",
          "Login.ForgotPassword": "Mot de passe oublié ?",
          "Login.FormHeadline": "Se connecter",
        }
      }
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      wait: true
    }
  });

export default i18n;