import React from 'react';
import {Redirect} from 'react-router-dom';
import {withTranslation, Trans} from 'react-i18next';
import {withSnackbar} from "notistack";

import PublicBase from "../../components/PublicBase/PublicBase";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";

import * as utils from "../../utils";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordNew: "",
      passwordRepeat: "",
      state: "init",
      working: false,
      errorPassword: false,
      errorRepeat: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { enqueueSnackbar, t } = this.props;
    this.setState({state: "init"});
    utils.callAPI("password/confirm", "POST", {key: this.props.match.params.recoverKey}).then(() => {
      this.setState({state: 'input'});
    }).catch(() => {
      enqueueSnackbar(t('ResetPassword.Error'), {variant:'error', autoHideDuration: 5000});
      this.setState({state: 'redirect'});
    });

  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]: value,
      errorPassword: false,
      errorRepeat: false
    });

  }

  handleSubmit(e) {
    const { enqueueSnackbar, t } = this.props;

    e.preventDefault();

    if (!utils.isValidPassword(this.state.passwordNew)) {
      this.setState({
        errorPassword: true
      });
      return;
    }

    if (this.state.passwordNew !== this.state.passwordRepeat) {
      this.setState({
        errorRepeat:true
      });
      return;
    }

    this.setState({
      working: true
    });

    utils.callAPI("password/reset", "POST", {key: this.props.match.params.recoverKey, password: this.state.passwordNew}).then(() => {
      enqueueSnackbar(t('ResetPassword.Success'), {variant:'success', autoHideDuration: 5000});
      this.setState({
        state: 'redirect'
      });
    }).catch(() => {
      enqueueSnackbar(t('ResetPassword.Error'), {variant:'error', autoHideDuration: 5000});
      this.setState({
        state: 'redirect'
      });
    });
  }

  render() {
    const {t} = this.props;
    return (
      <PublicBase
        infoHeadline={t('ResetPassword.InfoHeadline')}
        infoText={t('ResetPassword.InfoText')}
        contentHeadline={t('ResetPassword.FormHeadline')}
        inProgress={this.state.state === "init"}
      >
        {this.state.state === "redirect" ? <Redirect to="/login"/> : ''}
          <form onSubmit={this.handleSubmit} style={{minHeight:240}}>
            <div>
              <TextField
                id="passwordNew"
                label={t('ResetPassword.NewPassword')}
                value={this.state.passwordNew}
                type="password"
                onChange={this.handleChange}
                error={this.state.errorPassword}
                helperText={this.state.errorPassword ? t("ResetPassword.PasswordError") : " "}
                fullWidth
                autoFocus
                required
              />
            </div>
            <div>
              <TextField
                id="passwordRepeat"
                label={t('ResetPassword.NewPasswordRepeat')}
                value={this.state.passwordRepeat}
                type="password"
                onChange={this.handleChange}
                error={this.state.errorRepeat}
                helperText={this.state.errorRepeat ? t("ResetPassword.RepeatError") : " "}
                fullWidth
                required
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              working={this.state.working}
              className="public__submit"
            >
              <Trans>ResetPassword.Button</Trans>
            </Button>
          </form>
      </PublicBase>
    );
  }

}

export default withTranslation('translations')(withSnackbar(ResetPassword));