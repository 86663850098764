import React from "react";
import {Trans} from "react-i18next";

import SiteList from "./SiteList";

const SitesOverview = function() {
  return (
    <div>
      <h2 className="section__headline"><Trans>SitesOverview.Headline</Trans></h2>
      <div className="section__info"><Trans>SitesOverview.Info</Trans></div>
      <SiteList />
    </div>
  );
};

export default SitesOverview;