import React from "react";
import {Trans} from "react-i18next";
import {editSiteUrl} from "../../utils";
import Button from "../../components/Button/Button";

export default (props) => {
  const {
    siteId,
    ...rest
  } = props;

  return (
    <Button className="simple-button" href={editSiteUrl(siteId)} {...rest}><Trans>Common.EditSiteButton</Trans></Button>
  );
}