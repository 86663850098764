import React from 'react';
import { MenuItem } from "@material-ui/core";
import { Trans } from "react-i18next";

import OutlinedSelect from "../OutlinedSelect/OutlinedSelect";

import * as utils from "../../utils";

class CountrySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
    };
  }

  componentDidMount() {
    utils.callAPI("static/countries").then(data => {
      this.setState({
        countries: data.countries
      });
    }).catch();
  }

  render() {
    return (
      <OutlinedSelect {...this.props}>
        {this.state.countries.map(country =>
          <MenuItem value={country.id} key={country.id}><Trans>{country.country}</Trans></MenuItem>
        )}
      </OutlinedSelect>
    );
  }
}

export default CountrySelect;


