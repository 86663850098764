import React from 'react';
import {Redirect} from "react-router-dom";
import {logout} from "../../utils";

class Logout extends React.Component {
    componentDidMount() {
        logout(true);
    }

    render() {
        return (
            <div>
                <Redirect to='/login'/>
            </div>
        );
    }
}

export default Logout;