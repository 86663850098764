import React from 'react';
import ReactDOM from 'react-dom';
import {withStyles} from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  formControl: {
    margin: '16px 0 8px 0',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class OutlinedSelect extends React.Component {
  state = {
    labelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth + 10,
    });
  }

  render() {
    const {classes} = this.props;
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor={this.props.id}
        >
          {this.props.label}
        </InputLabel>
        <Select
          value={this.props.value}
          onChange={this.props.onChange}
          input={
            <OutlinedInput
              labelWidth={this.state.labelWidth}
              name={this.props.id}
              id={this.props.id}
            />
          }
        >
          {this.props.children}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(OutlinedSelect);
