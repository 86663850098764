import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import storage from "../../utils/storage";

export default ({ component: Component, ...routeProps}) => (
  <Route {...routeProps} render={props => (
    storage.getItem('authToken')
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: { from: props.location} }}/>
  )}/>
);
