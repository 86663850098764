import React from "react";
import { Trans } from 'react-i18next';

import "./SectionBox.scss";

export default (props) => {
  return (
    <section className={props.loading ? "section-box loading" : "section-box"} style={{width:props.width || '100%'}}>
      {props.headline ? <div className="section-box__headline"><Trans>{props.headline}</Trans></div> : ""}
      {props.info ? <div className="section-box__info">{props.info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}</div> : ""}
      {props.children}
    </section>
  )
}