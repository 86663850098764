import React from "react";
import SectionBox from "../../components/SectionBox/SectionBox";
import SimpleForm from "../../components/SimpleForm/SimpleForm";class SiteSeo extends React.Component {
  render() {
    let fields = [];
    fields.push({
      id: 'title',
      label: 'SiteSeo.Title',
      default: '',
      type: 'field',
      props: {
        required: true
      }
    });
    fields.push({
      id: 'author',
      label: 'SiteSeo.Author',
      default: '',
      type: 'field',
      props: {
        required: true
      }
    });
    fields.push({
      id: 'keywords',
      label: 'SiteSeo.Keywords',
      default: '',
      type: 'field',
      props: {
        required: true
      }
    });
    fields.push({
      id: 'descr',
      label: 'SiteSeo.Description',
      default: '',
      type: 'field',
      props: {
        required: true,
        multiline: true,
        rows: 4
      }
    });

    return (
      <SectionBox headline={'SiteSeo.Headline'} width={1000}>
        <SimpleForm fields={fields} api={'sites/' + this.props.id + "/seo/"}/>
      </SectionBox>
    );
  }
}

export default SiteSeo;