// IE 11 FIX/Polyfill
import "@babel/polyfill";

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';
import { SnackbarProvider } from 'notistack';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <I18nextProvider i18n={ i18n }>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <App />
    </SnackbarProvider>
  </I18nextProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
