import React from 'react'
import TextField from "@material-ui/core/TextField/TextField";

export default (props) => {
  return (<TextField
      style={{flexGrow:1}}
      id={props.id}
      name={props.id}
      variant="outlined"
      margin="normal"
       InputProps={{startAdornment: " "}}
      {...props}
  />
  )
};