import React from 'react';
import {Redirect, Link} from 'react-router-dom';
import {withTranslation, Trans} from 'react-i18next';
import {withSnackbar} from "notistack";

import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import * as utils from "../../utils";
import PublicBase from "../../components/PublicBase/PublicBase";

class ForgotPwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      state: "input",
      working: false,
      error: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { enqueueSnackbar, t } = this.props;
    this.setState({state: "input"});
    utils.log(this.props.match.params);
    if (this.props.match.params.recoverKey) {
      utils.callAPI("password/recover", "POST", {key: this.props.match.params.recoverKey}).then(data => {
        enqueueSnackbar(t('Password.RecoverySuccess'), {variant:'success', autoHideDuration: 5000});
        this.setState({state: 'redirect'});
      }).catch(error => {
        enqueueSnackbar(t('Password.RecoveryError'), {variant:'error', autoHideDuration: 5000});
        this.setState({state: 'redirect'});
      });
    }
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]: value,
      error: false
    });

  }


  handleSubmit(e) {
    const { enqueueSnackbar, t } = this.props;
    e.preventDefault();
    if (!utils.isEmail(this.state.email)) {
      this.setState({error:true});
      return;
    }

    utils.callAPI("password/request", "POST", {email: this.state.email}).then(data => {
      enqueueSnackbar(t('ForgotPwd.MessageSent'), {variant:'success', autoHideDuration: 5000});
      this.setState({state: 'redirect'});
    }).catch(error => {
      enqueueSnackbar(t('Password.RequestError'), {variant:'error', autoHideDuration: 2000});
      this.setState({
        working: false,
        error: true
      });
    });
    this.setState({
      working: true,
      error: false
    });
  }

  render() {
    const {t} = this.props;
    // Link Object for Redirecting via Button
    const LoginLink = props => <Link to="/login" {...props} />;

    return (
      <PublicBase
        infoHeadline={t('ForgotPwd.InfoHeadline')}
        infoText={t('ForgotPwd.InfoText')}
        contentHeadline={t('ForgotPwd.FormHeadline')}
      >
        {this.state.state === "redirect" ? <Redirect to="/login"/> : ''}
        <form onSubmit={this.handleSubmit}>
          <div style={{marginBottom:100}}>
            <TextField
              id="email"
              label={t('ForgotPwd.Email')}
              value={this.state.email}
              onChange={this.handleChange}
              error={this.state.error}
              helperText={this.state.error ? t("ForgotPwd.EmailInvalid") : " "}
              fullWidth
              autoComplete="email"
              autoFocus
              required
            />
          </div>
          <Button size="medium" component={LoginLink} className="public__link">
            <Trans>ForgotPwd.BackToLogin</Trans>
          </Button>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            working={this.state.working}
            className="public__submit"
          >
            <Trans>ForgotPwd.Button</Trans>
          </Button>
        </form>
      </PublicBase>
    );
  }

}

export default withTranslation('translations')(withSnackbar(ForgotPwd));