import React from "react";
import {Trans} from "react-i18next";
import Moment from "react-moment";

import SectionBox from "../../components/SectionBox/SectionBox";
import SimpleInfoRow from "../../components/SimpleInfoRow/SimpleInfoRow";
import ViewSiteButton from "../Sites/ViewSiteButton";
import EditSiteButton from "../Sites/EditSiteButton";

import * as utils from "../../utils";


class SiteInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      site: {
        creationDate: 0,
        modificationDate: 0,
        displayName: '',
        subscriptionPackage: '',
        subscriptionPeriodEnd: 0,
        domains: []
      }
    };
  }


  componentDidMount() {
    utils.callAPI("sites/" + this.props.id).then(
      data => {
        utils.log(data);
        this.setState({ready: true, site: data.site});
      }
    ).catch(utils.errorHandler);
  }

  render() {
    return (
      <SectionBox headline={<Trans>SiteInfo.Headline</Trans>} width={1000} ready={this.state.ready}>
        <SimpleInfoRow label={'SiteInfo.DisplayName'} value={this.state.site.displayName}/>
        <SimpleInfoRow label={'SiteInfo.Created'} value={<Moment>{this.state.site.creationDate}</Moment>}/>
        <SimpleInfoRow label={'SiteInfo.LastLogin'} value={<Moment>{this.state.site.lastLogin}</Moment>}/>
        <SimpleInfoRow label={'SiteInfo.SubscriptionPackage'} value={this.state.site.subscriptionPackage}/>
        <SimpleInfoRow label={'SiteInfo.SubscriptionUntil'}
                       value={
                         this.state.site.subscriptionUntil !== '0000-00-00'
                         ? <Moment>{this.state.site.subscriptionUntil}</Moment>
                         : <Trans>SiteInfo.Unlimited</Trans>
                       }
        />
        <SimpleInfoRow label={'SiteInfo.Domains'} value={this.state.site.domains.map(domain => {
          return (<div key={domain}>{domain}</div>);
        })}/>
        <div style={{textAlign: 'right'}}>
          <ViewSiteButton siteId={this.props.id}/>
          <EditSiteButton siteId={this.props.id}/>
        </div>
      </SectionBox>
    );
  }
}

export default SiteInfo;