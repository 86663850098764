import React from "react";
import {Trans} from "react-i18next";

import SectionBox from "../../components/SectionBox/SectionBox";
import UpgradeSiteButton from "../Sites/UpgradeSiteButton";

export default (props) => {
  return (
    <SectionBox headline={<Trans>SiteUpgrade.Headline</Trans>} width={1000}>
      <Trans>SiteUpgrade.Info</Trans>
      <div style={{textAlign:'right'}}>
        <UpgradeSiteButton siteId={props.site.id} size="large" variant="contained" color="primary"/>
      </div>
    </SectionBox>
  );
};