import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Trans, withTranslation} from 'react-i18next';

import PublicBase from "../../components/PublicBase/PublicBase";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";

import * as utils from "../../utils";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      working: false,
      emailInvalid: false,
      passwordInvalid: false,
      accountId: Number.parseInt(this.props.match.params.accountId, 10) || 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]: value, emailInvalid: false, passwordInvalid: false});
  }

  handleSubmit(e) {
    e.preventDefault();
    // Check format of email
    if (!utils.isEmail(this.state.email)) {
      this.setState({emailInvalid: true});
      return;
    }

    this.setState({
      working: true,
      emailInvalid: false,
      passwordInvalid: false
    });

    utils.callAPI("auth/login", "POST", {email: this.state.email, password: this.state.password, accountId: this.state.accountId || 0}).then(data => {
      if (data.redirect) {
        document.location.replace(data.redirect);
        return;
      }
      utils.login(data.authToken);
      this.setState({
        working: false,
        emailInvalid: false,
        passwordInvalid: false
      });
    }).catch(error => {
      utils.log(error);
      this.setState({
        working: false,
        emailInvalid: false,
        passwordInvalid: true
      });
    });
  }

  render() {
    utils.log("Cookie", document.cookie);
    const {t} = this.props;
    const classes = {form: 'none'};

    // No Login if already logged in
    if (utils.isLoggedIn()) {
      return (
        <Redirect to='/sites'/>
      )
    }

    utils.log("LoginState", this.state);

    // Link Object for Redirecting via Button
    const ForgotLink = props => <Link to="/forgot-password" {...props} />;

    return (
      <PublicBase
        infoHeadline={t('Login.InfoHeadline')}
        infoText={t('Login.InfoText')}
        contentHeadline={t('Login.FormHeadline') + (this.state.accountId ? " (" + this.state.accountId  + ")" : "")}
      >
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <TextField
            id="email"
            label={t('Login.Email')}
            autoComplete="email"
            autoFocus
            required
            fullWidth
            error={this.state.emailInvalid}
            helperText={this.state.emailInvalid ? t("Login.EmailInvalid") : " "}
            onChange={this.handleChange}
          />
          <TextField
            id="password"
            type="password"
            label={t("Login.Password")}
            autoComplete="current-password"
            margin="normal"
            required
            fullWidth
            error={this.state.passwordInvalid}
            helperText={this.state.passwordInvalid ? t("Login.PasswordInvalid") : " "}
            onChange={this.handleChange}
          />
          <Button size="medium" component={ForgotLink} className="public__link">
            <Trans>Login.ForgotPassword</Trans>
          </Button>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            working={this.state.working}
            className="public__submit"
          >
            <Trans>Login.Button</Trans>
          </Button>
        </form>
      </PublicBase>
    );
  }
}

export default withTranslation('translations')(Login);