import React from "react";
import {Trans, withTranslation} from "react-i18next";
import {MenuItem} from "@material-ui/core";
import {withSnackbar} from "notistack";
import SectionBox from "../../components/SectionBox/SectionBox";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";

import * as utils from "../../utils";
import OutlinedSelect from "../../components/OutlinedSelect/OutlinedSelect";


class SupportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized:false,
      working:false,
      sites: [],
      email: '',
      site: 0,
      name: '',
      reason: 0,
      message: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    const { enqueueSnackbar, t } = this.props;

    e.preventDefault();
    this.setState({...this.state, working:true});

    let submitData = {
      email: this.state.email,
      site: this.state.site,
      reason: this.state.reason,
      name: this.state.name,
      message: this.state.message,
    };

    utils.callAPI("support", "POST", submitData).then(data => {
      enqueueSnackbar(t("SupportForm.SendSuccess"), {variant:'success'});
      this.setState({...this.state, working:false, site:0, reason:0, message:''});
    }).catch((error) => {
      enqueueSnackbar(t("SupportForm.SendError"), {variant:'error'});
      this.setState({...this.state, working:false});
    });
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  componentDidMount() {
    utils.callAPI("support").then(
      data => {
        utils.log(data);
        this.setState({...data, initialized: true});
      }
    ).catch(error => {
    });
  }
  render() {
    const {t} = this.props;
    let siteItems = this.state.sites.map((site) => {
      return (
        <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
      );
    });
    return (
      <SectionBox headline={t("SupportForm.Headline")} info={t("SupportForm.InfoText")} initialized={this.state.initialized} width={1000}>
        <form onSubmit={this.handleSubmit}>
          <div>
            <TextField
              id="email"
              label={t("SupportForm.Email")}
              value={this.state.email}
              readOnly
              disabled
            />
          </div>
          <OutlinedSelect
            id="reason"
            label={t("SupportForm.Reason")}
            value={this.state.reason}
            onChange={this.handleChange}
          >
            <MenuItem value={0}><Trans>SupportForm.Reason0</Trans></MenuItem>
            <MenuItem value={1}><Trans>SupportForm.Reason1</Trans></MenuItem>
            <MenuItem value={2}><Trans>SupportForm.Reason2</Trans></MenuItem>
            <MenuItem value={3}><Trans>SupportForm.Reason3</Trans></MenuItem>
          </OutlinedSelect>
          <OutlinedSelect
            id="site"
            label={t("SupportForm.Site")}
            value={this.state.site}
            onChange={this.handleChange}
          >
            <MenuItem value={0}><Trans>SupportForm.NoSiteSelected</Trans></MenuItem>
            {siteItems}
          </OutlinedSelect>
          <div>
            <TextField
              id="name"
              label={t("SupportForm.Name")}
              value={this.state.name}
              onChange={this.handleChange}
              required
            />
          </div>
          <div>
            <TextField
              id="message"
              label={t("SupportForm.Message")}
              value={this.state.message}
              onChange={this.handleChange}
              multiline
              rows="10"
              required
            />
          </div>

          <div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              working={this.state.working}
              className="submit"
            >
              <Trans>SupportForm.Submit</Trans>
            </Button>
          </div>
        </form>
      </SectionBox>
    );
  }
}

export default withTranslation('translations')(withSnackbar(SupportForm));