import React, {useEffect, useState} from "react";
import SectionBox from "../../components/SectionBox/SectionBox";
import {Trans, useTranslation} from 'react-i18next';
import * as utils from "../../utils";
import Button from "../../components/Button/Button";


export default (props) => {
  const [state, setState] = useState(null);
  const [working, setWorking] = useState(false);
  useEffect(() => {
    utils.callAPI("payment").then(
      data => {
        setState(data.payment);
      }
    ).catch(error => {
      utils.log("ERROR: ", error);
    });
  }, []);

  const updatePaymentMethod = () => {
    utils.callAPI("payment", 'POST', {}).then(
      data => {
        if (data && data.url)
          document.location.replace(data.url);
      }
    )
  };

  const { t } = useTranslation();
  return (
    <SectionBox loading={state === null} headline={t("PaymentInfo.Headline")} info={t("PaymentInfo.InfoText")} width={1000}>
      {
        state && state.type === "none" &&
          <div style={{fontSize:22,marginBottom:20}}>
            {t("PaymentInfo.NoPaymentMethod")}
          </div>
      }
      {
        state && state.type === "card" &&
          <div style={{fontSize:22,marginBottom:20}}>
            Credit card issued by <b>{state.brand.charAt(0).toUpperCase() + state.brand.slice(1)}</b> ending on <b>{state.last4}</b> expiring <b>{state.exp_month}/{state.exp_year}</b>
          </div>
      }
      <div style={{textAlign:'right'}}>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          working={false}
          className="submit"
          onClick={updatePaymentMethod}
        >
          <Trans>PaymentInfo.Update</Trans>
        </Button>
      </div>
    </SectionBox>
  )
}