import React from "react";
import Base from "../../components/Base/Base";
import SupportForm from "./SupportForm";

export default (props) => {
  return (
    <Base>
        <SupportForm/>
    </Base>
  )
}