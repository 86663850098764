/*const storage = {
  clear: function() {
    window.storageItems = {};
  },

  setItem: function(key, value) {
    if (!('storageItems' in window)) {
      this.clear();
    }
    window.storageItems.item[key] = value;
  },

  getItem: function(key) {
    return ('storageItems' in window && key in window.storageItems) ? window.storageItems[key] : null;
  }
};*/

const storage = sessionStorage;

export default storage;