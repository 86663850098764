import React from 'react';
import { Trans } from 'react-i18next';
import { ExitToApp, AccountCircle, HeadsetMic, ViewModule } from '@material-ui/icons';

import NavLink from "../NavLink/NavLink";

import "./AppBar.scss"
import logo from "../../media/logo.svg";

const AppBar = () => {
  return (
    <header className="AppBar">
      <img className="AppBar__logo" src={logo} alt="" />
      <ul className="AppBar__navigation">
        <li className="AppBar__navigation__item"><NavLink to="/account"><AccountCircle/><Trans>AppBar.LinkAccount</Trans></NavLink></li>
        <li className="AppBar__navigation__item"><NavLink to="/sites"><ViewModule/><Trans>AppBar.LinkSites</Trans></NavLink></li>
        <li className="AppBar__navigation__item"><NavLink to="/support"><HeadsetMic/><Trans>AppBar.LinkSupport</Trans></NavLink></li>
        <li className="AppBar__navigation__item"><NavLink to="/logout"><ExitToApp/><Trans>AppBar.LinkLogout</Trans></NavLink></li>
      </ul>
    </header>
  );
};

export default AppBar;