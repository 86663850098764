import React from "react";
import {Trans, withTranslation} from "react-i18next";
import {withSnackbar} from "notistack";
import TextField from "../TextField/TextField";
import Button from "../Button/Button";
import * as utils from "../../utils";

class SimpleForm extends React.Component {
  constructor(props) {
    super(props);
    let data = {};
    this.props.fields.map((field) => {
      data[field.id] = field.default;
      return field;
    });

    this.state = {
      data: data,
      working: false,
      init: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({init: false});
    utils.callAPI(this.props.api).then((data) => {
      this.setState({init: true, data: data});
    }).catch(utils.errorHandler);
  }

  handleSubmit(e) {
    const {enqueueSnackbar, t} = this.props;
    e.preventDefault();
    this.setState({working: true});
    utils.callAPI(this.props.api, "POST", this.state.data).then(() => {
      enqueueSnackbar(t("Common.SaveSuccess"), {variant: 'success'});
      this.setState({working: false});
    }).catch(() => {
      enqueueSnackbar(t("Common.SaveError"), {variant: 'error'});
      this.setState({working: false});
    });
  }


  handleChange(e) {
    const {name, value} = e.target;
    this.setState(prevState => {
        return {
          data: {
            ...prevState.data, [name]: value
          }
        }
      }
    );
  }


  render() {
    const {t} = this.props;
    const fieldItems = this.props.fields.map((field) => {
      return (
        <TextField key={field.id} id={field.id} name={field.id} label={t(field.label)} onChange={this.handleChange}
                   value={this.state.data[field.id]} {...field.props} />
      );
    });
    return (
      <form onSubmit={this.handleSubmit}>
        {fieldItems}
        <div style={{textAlign:'right'}}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            working={this.state.working}
            className="button"
          >
            <Trans>{this.props.buttonText || 'Common.Save'}</Trans>
          </Button>
        </div>
      </form>
    );
  }
}

export default withTranslation('translations')(withSnackbar(SimpleForm));