import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import './App.scss';

import AuthRoute from "./components/AuthRoute/AuthRoute";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPwd/ForgotPwd";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

import Account from "./pages/Account/Account";
import Sites from "./pages/Sites/Sites";
import Logout from "./pages/Logout/Logout";
import Support from "./pages/Support/Support";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path='/login/:accountId' component={Login} />
        <Route path='/login' component={Login} />
        <Route path='/reset-password/confirm/:recoverKey' component={ResetPassword}/>
        <Route path='/forgot-password' component={ForgotPassword}/>
        <AuthRoute path="/account" component={Account} />
        <AuthRoute path="/sites" component={Sites} />
        <AuthRoute path="/support" component={Support}/>
        <AuthRoute path="/domains" component={Domains} />
        <AuthRoute path="/logout" component={Logout}/>
        <AuthRoute component={Default} />
      </Switch>
    </Router>
  );
};

const Domains = () => {
  return (
    <Redirect to="/sites" />
  );
};

const Default = () => {
  return (
    <Redirect to="/login" />
  );
};

export default App;
