import fetch from 'cross-fetch';
import Moment from "react-moment";
import storage from "./utils/storage";

Moment.globalFormat = 'MM/DD/YYYY';

const apiUrl = "https://members.page4.me/api/";
//const apiUrl = "/api/";

/**
 * Wrapper for utils.log for easier deactivation in the production build
 */

export function log() {
  //console.log(...arguments);
}

/**
 * Default error handler for promises
 * @param error
 */

export function errorHandler(error) {
  log(error);
}

export function login(authToken) {
  storage.setItem('authToken', authToken);
  window.pingTimer = setInterval(() => {
    callAPI("ping").catch(errorHandler);
  }, 30000)
}


/**
 * Logs the current user out of the dashboard
 *
 * @param callBackend Should the "logout" Api-Method be called?
 */

export function logout(callBackend = false) {
    storage.clear();
    if (window.pingTimer) {
      clearInterval(window.pingTimer);
    }
  document.cookie = 'C4PLOGINID=; path=/; domain=.members.page4.me; expires=' + new Date(0).toUTCString();
  //document.cookie = 'C4PLOGINID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    if (callBackend) {
      callAPI("auth/logout").catch();
    }
}

export function isLoggedIn() {
  if (storage.getItem('authToken')) {
    return true;
  }

  const value = "; " + document.cookie;
  const parts = value.split("; C4PLOGINID=");
  const authToken = (parts.length === 2) ? parts.pop().split(";").shift() : false;
  if (authToken && authToken.length > 0) {
    login(authToken);
    return true;
  }

  return false;
}


/**
 * ResponseHandler for the callAPI function
 * @param response
 * @returns {*} A promise containing the data or the error
 */

function handleResponse(response) {
  if (!response) {
    return Promise.reject("No valid repsonse");
  }
  // TODO: ContextType prüfen?
  return response.text().then(text => {
    log(text);
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return ('success' in data && data.success) ? data.payload : Promise.reject("CustomError: " + data.errorMsg);
  });
}

/**
 * Calls an page4 API
 *
 * @param api
 * @param method {string} The HTTP-Method to be used in the call (POST,GET)
 * @param data
 * @returns {Promise<any | void>}
 */

export async function callAPI(api, method = 'GET', data = {}) {
  let response = await fetch(apiUrl + api, {
    method: method,
    credentials: 'include',
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
    body: (method !== "GET" ? JSON.stringify(data) : null),
  }).catch(reason => log("IO Error ", reason.message));
  return await handleResponse(response);
}

/**
 * Checks if the format of an email is valid
 *
 * @param {string} email
 * @returns {boolean}
 */

export function isEmail(email) {
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

/**
 * Checks if a password meets the security requirements
 *
 * @param {string} password
 * @returns {boolean}
 */
export function isValidPassword(password) {
  const regexUppercase = /[A-Z]+/;
  const regexLowercase = /[a-z]+/;
  const regexNumber = /[0-9]+/;
  return password.length > 7 && regexUppercase.test(password) && regexLowercase.test(password) && regexNumber.test(password);
}


/**
 * Opens the legacy Account Upgrader for the specified account ID
 *
 * @param siteId
 */

export const openLegacyUpgrade = (siteId) => {
  window.closeUpgrade = () => {
    window.c4p_upgrade.close2019(siteId);
  };
  window.c4p_upgrade.show2019(siteId);
};

/**
 * Generates an URL to view the site
 *
 * @param siteId
 * @returns {string} URL
 */

export const viewSiteUrl = (siteId) => {
  return apiUrl + 'sites/' + siteId + '/view/';
};

/**
 * Generates an URL to edit the site
 *
 * @param siteId
 * @returns {string} URL
 */

export const editSiteUrl = (siteId) => {
  return apiUrl + 'sites/' + siteId + '/edit/';
};