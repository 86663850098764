import React from "react";
import {Trans, withTranslation} from "react-i18next";
import {withSnackbar} from "notistack";
import SectionBox from "../../components/SectionBox/SectionBox";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";

import * as utils from "../../utils";
import CountrySelect from "../../components/CountrySelect/CountrySelect";


class PersonalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
        organisation: '',
        firstName: '',
        lastName: '',
        street: '',
        streetNumber: '',
        postCode: '',
        city: '',
        country: 81,
        phone: '',
        fax: ''
      },

      working:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    const { enqueueSnackbar, t } = this.props;

    e.preventDefault();
    this.setState({working:true});
    utils.callAPI("account", "POST", this.state.data).then(data => {
      enqueueSnackbar(t("PersonalData.SaveSuccess"), {variant:'success'});
      this.setState({working:false});
    }).catch((error) => {
      enqueueSnackbar(t("PersonalData.SaveError"), {variant:'error'});
      this.setState({working:false});
    });
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState(prevState => {return {data: {...prevState.data,[name]: value}}});
    utils.log(this.state);
  }

  componentDidMount() {
    utils.callAPI("account").then(
      data => {
        this.setState({data: data});
      }
    ).catch(error => {
      utils.log("ERROR: ", error);
    });
  }
  render() {
    const {t} = this.props;
    return (
      <SectionBox headline={t("PersonalData.Headline")} info={t("PersonalData.InfoText")} width={1000}>
        <form onSubmit={this.handleSubmit}>
          <div>
            <TextField
              id="email"
              label={t("PersonalData.Email")}
              value={this.state.data.email}
              readOnly
              disabled
            />
          </div>
          <div>
            <TextField
              id="organisation"
              label={t("PersonalData.Organisation")}
              value={this.state.data.organisation}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              style={{marginRight:10,flexGrow:1}}
              id="firstName"
              label={t("PersonalData.FirstName")}
              value={this.state.data.firstName}
              onChange={this.handleChange}
            />
            <TextField
              id="lastName"
              label={t("PersonalData.LastName")}
              value={this.state.data.lastName}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              id="street"
              label={t("PersonalData.Street")}
              value={this.state.data.street}
              onChange={this.handleChange}
            />
            <TextField
              style={{marginLeft:10,minWidth:200,width:'25%'}}
              id="streetNumber"
              label={t("PersonalData.StreetNumber")}
              value={this.state.data.streetNumber}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <TextField
              style={{marginRight:10,minWidth:200,width:'25%'}}
              id="postCode"
              label={t("PersonalData.PostCode")}
              value={this.state.data.postCode}
              onChange={this.handleChange}
            />
            <TextField
              id="city"
              label={t("PersonalData.City")}
              value={this.state.data.city}
              onChange={this.handleChange}
            />
          </div>
          <CountrySelect id={'country'} value={this.state.data.country} onChange={this.handleChange} label={t("PersonalData.Country")}/>
          <div>
            <TextField
              style={{marginRight:10,width:'50%'}}
              id="phone"
              label={t("PersonalData.Phone")}
              value={this.state.data.phone}
              onChange={this.handleChange}
            />
            <TextField
              id="fax"
              label={t("PersonalData.Fax")}
              value={this.state.data.fax}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              working={this.state.working}
              className="submit"
            >
              <Trans>PersonalData.Submit</Trans>
            </Button>
          </div>
      </form>
      </SectionBox>
    );
  }
}

export default withTranslation('translations')(withSnackbar(PersonalData));