import React from "react";
import {Trans} from "react-i18next";
import {viewSiteUrl} from "../../utils";
import Button from "../../components/Button/Button";

export default (props) => {
  const {
    siteId,
    ...rest
  } = props;

  return (
    <Button {...rest} target="_blank" className="simple-button" href={viewSiteUrl(siteId)}><Trans>Common.ViewSiteButton</Trans></Button>
  );
}