import React from "react";
import Base from "../../components/Base/Base";
import PersonalData from "./PersonalData";
import Password from "./Password"
import PaymentData from "./PaymentData";

export default (props) => {
 return (
   <Base>
     <PersonalData/>
     <Password/>
     <PaymentData/>
   </Base>
 )
}