import React from "react";
import {Trans, withTranslation} from "react-i18next";
import {withSnackbar} from "notistack";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import SectionBox from "../../components/SectionBox/SectionBox";
import {callAPI} from "../../utils";

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      working:false,
      errorNewPassword: false,
      errorOldPassword: false
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFocus() {
    this.setState({
      errorNewPassword: false,
      errorOldPassword: false
    });
  }

  handleSubmit(e) {
    const {t, enqueueSnackbar} = this.props;
    e.preventDefault();
    this.setState({errorNewPassword: false, errorOldPassword: false, working:true});
    if (this.state.newPassword !== this.state.newPasswordRepeat) {
      this.setState({working: false, errorNewPassword: true});
    } else {
      callAPI("account/password", "POST", {oldPassword: this.state.oldPassword, newPassword: this.state.newPassword}).then(data => {
        enqueueSnackbar(t("Password.SaveSuccess"), {variant:'success'});
        this.setState({
          working:false,
          currentPassword: '',
          newPassword: '',
          newPasswordRepeat: '',
        });
      }).catch(() => {
        enqueueSnackbar(t("Password.SaveError"), {variant:'error'});
        this.setState({
          working:false,
          errorOldPassword: true,
        });
      });
    }
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  componentDidMount() {
    this.setState({
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      working:false,
    });
  }

  render() {
    const {t} = this.props;
    return (
      <SectionBox headline={t("Password.Headline")} info={t("Password.InfoText")} width={1000}>
        <form onSubmit={this.handleSubmit} style={{maxWidth:600}}>
          <div>
            <TextField
              id="oldPassword"
              label={t("Password.OldPassword")}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              type="password"
              required
              helperText={this.state.errorOldPassword ? t("Password.IncorrectError") : " "}
              error={this.state.errorOldPassword}
            />
          </div>
          <TextField
            id="newPassword"
            label={t("Password.NewPassword")}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            type="password"
            required
            helperText={this.state.errorNewPassword ? t("Password.RepeatError") : " "}
            error={this.state.errorNewPassword}
          />
          <TextField
            id="newPasswordRepeat"
            label={t("Password.NewPasswordRepeat")}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            type="password"
            required
            helperText={this.state.errorNewPassword ? t("Password.RepeatError") : " "}
            error={this.state.errorNewPassword}
          />
          <div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              working={this.state.working}
              className="submit"
            >
              <Trans>Password.Submit</Trans>
            </Button>
          </div>
        </form>
      </SectionBox>

    );
  }
}

export default withTranslation('translations')(withSnackbar(Password));