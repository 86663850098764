import React from "react";
import {Route} from "react-router-dom";

import Base from "../../components/Base/Base";
import SitesOverview from "./SitesOverview";
import SiteDashboard from "../SiteDashboard/SiteDashboard";

import "./Sites.scss";

const Sites = function() {
  return (
    <Base>
      <Route exact path="/sites/" component={SitesOverview} />
      <Route path="/sites/:siteId/dashboard" component={SiteDashboard} />
    </Base>
  );
};

export default Sites;